import { NavLink, useNavigate } from "react-router-dom";
import {
  Button,
  CssBaseline,
  Paper,
  Alert,
  Divider,
  FormControl,
} from "@mui/material";
import { Grid, Typography, Box, Stack, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Logo from "../../images/logo3.png";
// import googleLogo from "../../images/googleLogo.png";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { useLoginUserMutation } from "../../services/userAuthApi";
import { getToken, storeToken } from "../../services/CookieStorageService";
// import { getToken, storeToken } from "../../services/LocalStorageService";
import { useDispatch } from "react-redux";
// import { setUserToken } from "../../features/authSlice";
import { login } from "../../features/authSlice";
import jwt_decode from "jwt-decode";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

// import bglogin from "../../images/bgloginx2.png";
// import { useSpring, animated } from "react-spring";
// import {
//   MouseParallaxContainer,
//   MouseParallaxChild,
// } from "react-parallax-mouse";
import Cookies from "js-cookie";
import { GoogleLogin, GoogleLogout } from "react-google-login";
// import { GoogleLogin } from "@react-oauth/google";
import { baseUrl } from "../../services/baseUrl";
import axios from "axios";

// const baseUrl = "http://localhost:8000";

const Login = () => {
  const [server_error, setServerError] = useState({});
  const [isErrorForEmailUsername, setIsErrorForEmailUsername] = useState(false);
  const [isErrorForPassword, setIsErrorForPassword] = useState(false);
  const [errorMsgForEmailUsername, setErrorMsgForEmailUsername] = useState("");
  const [errorMsgForPassword, setErrorMsgForPassword] = useState("");

  const navigate = useNavigate();
  const [loginUser, { isLoading }] = useLoginUserMutation().map((x) => x);
  const dispatch = useDispatch();

  const [passwordShown, setPasswordShown] = useState(false);
  const handleClickShowPassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const google_clientID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID;

  // const [showPassword, setShowPassword] = useState(false);

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };
  // const handleGoogleLogin = (response) => {
  //   axios
  //     .post(`${baseURL}/auth/convert-token`, {
  //       token: response.accessToken,
  //       backend: "google-oauth2",
  //       grant_type: "convert_token",
  //       client_id: drfClientId,
  //       client_secret: drfClientSecret,
  //     })
  //     .then((res) => {
  //       const { access_token, refresh_token } = res.data;
  //       console.log({ access_token, refresh_token });
  //       Cookies.set("access_token", access_token);
  //       Cookies.set("refresh_token", refresh_token);
  //       var userId = 1234;
  //       dispatch(login({ access_token, refresh_token, userId }));

  //       // console.log("direct to home");
  //       navigate("/home");
  //     })
  //     .catch((err) => {
  //       console.log("Error Google login", err);
  //     });
  // };
  const handleSuccess = (response) => {
    const { tokenId } = response;
    // console.log(
    //   response,
    //   "this  is success response from google authentication"
    // );
    axios
      .post(`${baseUrl}/api/user/auth-google/`, {
        access_token: tokenId,
      })
      .then((res) => {
        // localStorage.setItem('access_token', res.data.access_token);
        // localStorage.setItem('refresh_token', res.data.refresh_token);
        // Handle successful login
        storeToken(res.data.token);
        // console.log(res.data.token.access);
        const accessToken = res.data.token.access;
        const refreshToken = res.data.token.refresh;
        const data = jwt_decode(res.data.token.access);
        const userId = data.user_id;
        dispatch(login({ accessToken, refreshToken, userId }));
        const isNewUser = res.data.isNewUser;
        if (isNewUser) {
          navigate("/set-username");
        } else {
          // console.log("direct to home");
          navigate("/home");
        }
      })
      .catch((err) => {
        console.error("Google login error:", err);
      });
  };

  const handleFailure = (response) => {
    console.error("Google login failed:", response);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); //# prevent reloading of the page
    setIsErrorForEmailUsername(false);
    setErrorMsgForEmailUsername("");
    setIsErrorForPassword(false);
    setErrorMsgForPassword("");
    setServerError({});

    const data = new FormData(e.currentTarget);
    const actualData = {
      email_or_username: data.get("email_or_username"), //in branckets the values should be the ones in the name attribute of the tag
      password: data.get("password"),
    };
    const res = await loginUser(actualData);
    if (res.error) {
      setServerError(res.error.data.errors);
      if (res.error.data.errors.email_or_username) {
        setIsErrorForEmailUsername(true);
        setErrorMsgForEmailUsername("Email Cannot be Blank");
      }
      if (res.error.data.errors.password) {
        setIsErrorForPassword(true);
        setErrorMsgForPassword("Incorrect Password");
      }

      console.log(res.error.data.errors);
    }
    if (res.data) {
      storeToken(res.data.token);

      //storing the tokens in the state so that we can access them for login check on any page . slicing is used for same
      let { access_token, refresh_token } = getToken();
      const accessToken = access_token;
      const refreshToken = refresh_token;
      const data = jwt_decode(access_token);
      const userId = data.user_id;
      dispatch(login({ accessToken, refreshToken, userId }));

      // console.log("direct to home");
      navigate("/home");
    }
  };

  useEffect(() => {
    //get cookie
    //if its blank we redirect to home
    //or else nothing
    const accessToken = Cookies.get("access_token");
    if (accessToken) {
      navigate("/home");
    }
  }, []);

  const [mouse, setMouse] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const container = document.getElementById("container");
    const wdt = container.clientWidth;
    const hgt = container.clientHeight;

    const handleMouseMove = (e) => {
      if (!container.contains(e.target)) {
        setMouse({ x: 0, y: 0 });
      }
      if (container && container.contains(e.target)) {
        setMouse({
          x: (e.clientX - 0.5 * wdt) / 10,
          y: (e.clientY - 0.5 * hgt) / 10,
        });
      }
      // setMouse({ x: e.clientX / 20, y: e.clientY / 20 });
    };

    // console.log(mouse.x, mouse.y);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouse]);

  return (
    <>
      <CssBaseline></CssBaseline>
      <Grid
        container
        // id="container"
        sx={{
          height: "100vh",
          // justifyContent: "right",

          backgroundColor: "#000000",
          // display: "flex",
        }}
      >
        <Grid
          item
          lg={8}
          md={6}
          sm={12}
          xs={12}
          id="container"
          sx={{
            // position: "relative",
            backgroundColor: "black",
            px: "20px",
            height: ["200px", "200px", "100%", "100%"],
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box
            sx={{
              // display: "block",
              // position: "absolute",
              // backgroundColor: "orange",
              width: ["60%", "50%", "60%", "40%"],
              // transition: "0.2s",
              // transform: "translate(400px, 40px)",
              // transform: `translate(${mouse.x}px, ${mouse.y}px)`,
            }}
          >
            {/* <MouseParallaxContainer>
              <MouseParallaxChild factorX={0.03} factorY={0.05}> */}
            <img src={Logo} width="100%" />
            {/* </MouseParallaxChild>
            </MouseParallaxContainer> */}
          </Box>
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          sm={12}
          xs={12}
          sx={{
            backgroundColor: "#DCF3FF",

            height: ["auto", "100%", "100%", "100%"],
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              // backgroundColor: "red",
              pt: ["20px", "30px", "12vh", "15vh"],
            }}
          >
            <Typography
              sx={{
                fontSize: "1.6rem",
                color: "#153D4B",
                fontWeight: "medium",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              Welcome to Studnerd!
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              // backgroundColor: "red",
              pt: "16px",
            }}
          >
            <Typography
              align="center"
              sx={{
                fontSize: "0.96rem",
                textAlign: "center",
                color: "#153D4B",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              By continuing you indicate that you agree Studnerd's
            </Typography>
            <Typography
              sx={{
                fontSize: "0.96rem",
                textAlign: "center",
                color: "#153D4B",
                fontFamily:
                  "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
              }}
            >
              <Link component={NavLink} to="/terms-of-service">
                Terms of Service
              </Link>
              &nbsp;and&nbsp;
              <Link component={NavLink} to="/privacy-policy">
                Privacy Policy
              </Link>
            </Typography>
          </Box>
          <Box
            component="form"
            noValidate
            id="login-form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              pt: "24px",
              textAlign: "center",
              // pl: ["20px", "20px", "80px", "100px"],
            }}
          >
            <Box
              sx={{
                width: "60%",
                // backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
                // backdropFilter: `blur(3px)`,
              }}
            >
              <TextField
                error={isErrorForEmailUsername}
                margin="normal"
                required
                id="email_or_username"
                name="email_or_username"
                label="Email or Username"
                variant="filled"
                size="small"
                helperText={errorMsgForEmailUsername}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.8rem",
                    color: "black",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.8rem",
                    color: "#153D4B",
                  },
                }}
                sx={{
                  width: "100%",
                  // backgroundColor: "white",
                }}
              />
              {/* <FormControl sx={{ width: "100%", mt: "15px" }}>
                <InputLabel
                  htmlFor="filled-adornment-password"
                  sx={{
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.8rem",
                    color: "#153D4B",
                    // mt: "10px",
                  }}
                >
                  Password
                </InputLabel> */}
              <TextField
                error={isErrorForPassword}
                margin="normal"
                required={true}
                id="filled-adornment-password"
                name="password"
                label="Password"
                type={passwordShown ? "text" : "password"}
                autoComplete="current-password"
                variant="filled"
                size="small"
                helperText={errorMsgForPassword}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.8rem",
                    color: "black",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {passwordShown ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    fontFamily:
                      "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                    fontSize: "0.8rem",
                    color: "#153D4B",
                  },
                }}
                sx={{
                  width: "100%",
                  // backgroundColor: "white",
                  fontFamily:
                    "Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.8rem",
                  color: "black",
                }}
              />
              {/* </FormControl> */}
              {server_error.non_field_errors ? (
                <Alert
                  severity="error"
                  sx={{ borderRadius: "10px", border: "1px solid red" }}
                >
                  <Typography
                    sx={{ fontSize: "0.8rem", fontFamily: "sans-serif" }}
                  >
                    {server_error.non_field_errors[0]}
                  </Typography>
                </Alert>
              ) : (
                ""
              )}
              {/* <Box sx={{ width: "100%" }}>
                <Button sx={{}} onClick={togglePasswordVisiblity}>
                  <Typography
                    sx={{ textTransform: "none", fontSize: "0.6rem" }}
                  >
                    Show password
                  </Typography>
                </Button>
              </Box> */}
              <Box
                sx={{
                  width: "100%",
                  mt: "16px",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  // backgroundColor: "blue",
                  display: "flex",
                }}
              >
                <Link
                  component={NavLink}
                  to="/forgot-password"
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily:
                        "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                      fontSize: "0.8rem",
                      color: "#393939",
                    }}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              </Box>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Button
                  // disabled
                  variant="contained"
                  type="submit"
                  size="small"
                  sx={{
                    width: "100%",
                    mt: "24px",
                    textTransform: "none",
                    backgroundColor: "#66CCFF",
                    boxShadow: "-3px -3px 4px #FFFFFF, 3px 3px 4px #C3CEDE",
                    border: "1px solid #dddddd",
                    "&:hover": {
                      border: "1px solid #dddddd",
                      backgroundColor: "#87D7FF",
                      boxShadow: "-5px -5px 8px #FFFFFF, 5px 5px 8px #C3CEDE",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "0.8rem",
                      fontFamily: "Montserrat",
                      color: "white",
                    }}
                  >
                    Login
                  </Typography>
                </Button>
              )}
            </Box>
            <Box
              sx={{
                width: "60%",
                // backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
                // backdropFilter: `blur(3px)`,
              }}
            >
              {/* <Typography sx={{ fontFamily: "montserrat" }}>
                We are currently fixing some bugs. Check back later.
              </Typography> */}
              <Divider sx={{ mt: "20px" }} />
              {/* <div
                id="g_id_onload"
                data-client_id="677237612496-dfm3sg2e71qtrq9atr3h52s437aiv375.apps.googleusercontent.com"
                data-context="signin"
                data-ux_mode="popup"
                data-login_uri="localhost:3000"
                data-auto_prompt="false"
              ></div>

              <div
                class="g_id_signin"
                data-type="standard"
                data-shape="rectangular"
                data-theme="filled_black"
                data-text="continue_with"
                data-size="large"
                data-logo_alignment="left"
              ></div> */}
              <Box sx={{ mt: "20px" }}>
                <GoogleLogin
                  style={{ opacity: "1" }}
                  clientId={google_clientID}
                  buttonText="Login with Google"
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  cookiePolicy={"single_host_origin"}
                  // isSignedIn={true} keep this commented or else the code going into infinite loop at logout
                />

                {/* <GoogleLogin
                  // clientId={google_clientID}
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  cookiePolicy={"single_host_origin"}
                /> */}
              </Box>
              <Divider sx={{ mt: "20px" }} />

              {/* <GoogleLogin
                clientId={googleClientId}
                buttonText="LOGIN WITH GOOGLE"
                onSuccess={(response) => handleGoogleLogin(response)}
                render={(renderProps) => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    type="button"
                    class="login-with-google-btn"
                  >
                    Sign in with Google
                  </button>
                )}
                onFailure={(err) => console.log("Google Login failed", err)}
              /> */}
              {/* <Button
                variant="outlined"
                // startIcon={<img src={googleLogo} width="30px" />}
                // startIcon={<GoogleIcon />}
                sx={{
                  mt: "30px",

                  fontFamily: "Segoe UI",
                  fontSize: "0.8rem",
                  fontWeight: "regular",
                  border: "1px solid #707070",
                  borderRadius: "7px",
                  alignItems: "center",
                  textTransform: "none",
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#F6F6F6",
                  },
                }}
              >
                <Typography sx={{ fontSize: "1rem", fontFamily: "Segoe UI" }}>
                  Sign in with Google
                </Typography>
              </Button> */}

              {/* from here down uncomment that link */}

              <Box
                sx={{
                  width: "100%",
                  mt: "16px",
                  mb: "3.2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize: "0.8rem", fontFamily: "Segoe UI" }}>
                  Don't have an Account?&nbsp;
                </Typography>
                <Link
                  component={NavLink}
                  to="/signup"
                  sx={{ textDecoration: "none" }}
                >
                  <Typography
                    sx={{ fontSize: "0.8rem", fontFamily: "Segoe UI" }}
                  >
                    Sign up
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              // mt: "25%",
              // mt: "30px",
              opacity: "0.8",
              position: ["relative", "fixed", "fixed", "fixed"],
              // left: 0,
              bottom: 0,
              width: ["100vw", "100vw", "50vw", "33.3vw"],
              // width: "inherit",
              // backgroundColor: "white",
              // backgroundColor: "#E8F9FF",
              display: "flex",
              minHeight: "3.2rem",
              alignItems: "center",
              // pt: "80vh",
              // backgroundColor: "orange",
              // py: "1rem",
              // pl: {
              //   lg: "0px",
              //   md: "0px",
              //   sm: "0px",
              //   xs: "3rem",
              // },
              justifyContent: "space-around",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "row",
                xs: "column",
              },
            }}
          >
            <Link
              component={NavLink}
              to="/about-us"
              sx={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#153D4B",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                About Us
              </Typography>
            </Link>
            <Link
              component={NavLink}
              to="/terms-of-service"
              sx={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#153D4B",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                Terms of Service
              </Typography>
            </Link>
            <Link
              component={NavLink}
              to="/privacy-policy"
              sx={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#153D4B",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                Privacy Policy
              </Typography>
            </Link>
            <Link
              component={NavLink}
              to="/blogs"
              sx={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#153D4B",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                Blogs
              </Typography>
            </Link>
            {/* <Link sx={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: "0.8rem", color: "#153D4B" }}>
                  FAQs
                </Typography>
              </Link> */}
            {/* <Link sx={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: "0.8rem", color: "#153D4B" }}>
                  Our Team
                </Typography>
              </Link>
              <Link
                component={NavLink}
                to="/investors"
                sx={{ textDecoration: "none" }}
              >
                <Typography sx={{ fontSize: "0.8rem", color: "#153D4B" }}>
                  Investors
                </Typography>
              </Link> */}
            <Link
              component={NavLink}
              to="/help"
              sx={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: "0.75rem",
                  color: "#153D4B",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                }}
              >
                Help and Support
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Login;
