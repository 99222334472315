import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import NotesSearchResultCard from "./NotesSearchResultCard";
import DefaultNotesCard from "./DefaultNotesCard";
import CircularProgress from "@mui/material/CircularProgress";
import { getToken } from "../services/CookieStorageService";
import { baseUrl } from "../services/baseUrl";

const DefaultNotesComponent = (props) => {
  const [noteSearchResult, setnoteSearchResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const limit = 20;
  const [scrollPosition, setScrollPosition] = useState("");
  const [scrollType, setScrollType] = useState("notes");
  const { access_token } = getToken();

  //getdefaultfeednotes
  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(
          `${baseUrl}/api/posts/getdefaultfeednotes/?offset=${offset}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${access_token}`,
            },
            signal: abortController.signal,
          }
        );
        const data = await response.json();
        if (isMounted) {
          // console.log("notes saved in default notes", data);
          setnoteSearchResult((prev) => [...prev, ...data]);
          // console.log(data.length);
          setHasMore(data.length > 0);

          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
    };
  }, [offset]);

  useEffect(() => {
    const handleInfiniteScroll = async () => {
      try {
        if (
          window.innerHeight + document.documentElement.scrollTop + 1 >=
            document.documentElement.scrollHeight &&
          !isLoading &&
          hasMore
        ) {
          // if (hasMore) {
          setOffset((prev) => prev + 20);
          // }
        }
      } catch (error) {
        console.log(error);
      }
    };
    window.addEventListener("scroll", handleInfiniteScroll);

    return () => {
      window.removeEventListener("scroll", handleInfiniteScroll);
    };
  }, [isLoading, hasMore]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleInfiniteScroll);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      setScrollPosition(currentPosition.toString());
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem("notes_scrollPosition");
    if (storedScrollPosition !== null) {
      const parsedScrollPosition = parseInt(storedScrollPosition, 10);
      setScrollPosition(parsedScrollPosition);
      // console.log("scroll position for default feed", parsedScrollPosition);
      // document.documentElement.scrollTop = scrollPosition;
      window.scrollTo(0, parseInt(scrollPosition));
    }
  }, [isLoading]);

  return (
    <>
      <Grid
        container
        sx={{
          height: "auto",
          justifyContent: "center",
          // backgroundColor: "#EBF9FF",
        }}
      >
        <Grid
          item
          lg={3}
          md={0}
          sm={0}
          xs={0}
          sx={{
            // backgroundColor: "blue",
            height: "auto",
          }}
        >
          {null}
        </Grid>

        {/* {!isLoading ? ( */}
        <Grid
          item
          lg={6}
          md={8}
          sm={12}
          xs={12}
          sx={{
            // backgroundColor: "#EBF9FF",
            height: "auto",
            p: "10px",
          }}
        >
          {typeof noteSearchResult !== "undefined"
            ? noteSearchResult.map((note) => (
                <DefaultNotesCard
                  key={note.id}
                  noteInfo={note}
                  scrollType={scrollType}
                  scrollPosition={scrollPosition}
                />
              ))
            : ""}
          {!isLoading ? (
            ""
          ) : (
            <Box
              sx={{
                mt: "8px",
                width: "100%",
                p: "10px",
                borderRadius: "10px",
                ml: "10px",
                height: "200px",
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {!hasMore ? (
            <Box
              sx={{
                mt: "8px",
                width: "100%",
                p: "10px",
                borderRadius: "10px",
                ml: "10px",
                height: "30px",
                // backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "white",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  fontSize: "0.7rem",
                }}
              >
                That's all we have. New content is being uploaded on studnerd
                everyday.
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontFamily:
                    "Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif",
                  display: "flex",
                  fontSize: "0.7rem",
                }}
              >
                {/* And our team is working round the clock to get you more quality
                content.  */}
                Thank you for choosing Studnerd!
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Grid>
        {/* ) : (
           <Box
            sx={{
              mt: "8px",
              width: "100%",
              p: "10px",
              borderRadius: "10px",
              ml: "10px",
              height: "200px",
              // backgroundColor: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box> */}
        {/* <Box
            sx={{
              height: "calc(100vh-96px)",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        )} */}

        <Grid
          item
          lg={3}
          md={4}
          sm={0}
          xs={0}
          sx={{
            // backgroundColor: "orange",
            // position: "fixed",

            width: "100%",
            right: "0",
            height: "fit-content", // add this
            // height: "100vh", // add this
            display: {
              lg: "block",
              md: "block",
              sm: "none",
              xs: "none",
            },
          }}
        ></Grid>
      </Grid>
    </>
  );
};

export default DefaultNotesComponent;

// {props.searchType === "notes" &&
// typeof noteSearchResult !== "undefined"
//   ? noteSearchResult.map((note) => (
//       <div key={note.id}>
//         <Box sx={{ p: "4px" }}>
//           <NotesSearchResultCard
//             id={note.id}
//             title={note.title}
//             owner={note.owner}
//             content={note.content}
//             uploadedOn={note.uploaded_on}
//             views={note.views}
//             upvotes={note.upvotes}
//           />
//         </Box>
//       </div>
//     ))
//   : ""}
